import styled from "@emotion/styled";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "../Components/Navbar";
import { useAuth } from "../Context/AuthContext";
import Colors from "../Theme/Colors";
import AllJobRoles from "./AllJobRoles";
import AllProspects from "./AllProspects";
import CreateJobRole from "./CreateJobRole";
import CreateProspect from "./CreateProspect";
import Login from "./Login";
import NotFound from "./NotFound";
import CircularLoader from "../Components/CircularLoader";

const PageWrapper = styled("main")`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.background};
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

export const RouteName = {
  home: "/",
  createJobRole: "/create-job-role",
  createProspect: "/create-prospect",
  jobRole: "/job-role",
  notFound: "/404",
  login: "/login",
};

const Routers = () => {
  const { token, appLoading } = useAuth();
  if(appLoading) return <CircularLoader/>
  if (!token) {
    return <Login />;
  }
  return (
    <PageWrapper>
      {/* <Route path={RouteName.login} element={<Login />} />; */}
      <Navbar />
      <Routes>
        <Route path={RouteName.home} element={<AllJobRoles />} />
        <Route path={RouteName.createJobRole} element={<CreateJobRole />} />
        <Route
          path={`${RouteName.jobRole}/:roleId${RouteName.createProspect}`}          element={<CreateProspect />}
        />
        <Route
          path={`${RouteName.jobRole}/:roleId`}
          element={<AllProspects />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </PageWrapper>
  );
};

export default Routers;
