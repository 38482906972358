import request from "../utils/api";
import { apiConfigs } from "../utils/configs";
import { getToken } from "./AuthService";

// const TOKEN = process.env.REACT_APP_AUTH_TOKEN;
const getJobRoles = async () => {
  return await request({
    url: apiConfigs.routes.getJobRoles,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

const getJobRole = async (id) => {
  const res = await request({
    url: `${apiConfigs.routes.getJobRole}${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  if (!res?.[0]) throw new Error("JOBROLE_ERROR");
  return res[0];
};

const addJobRole = async (name) => {
  return await request({
    url: apiConfigs.routes.addJobRole,
    method: "POST",
    data: {
      name,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

const deleteJobRole = async (_ids) => {
  return await request({
    url: apiConfigs.routes.deleteJobRole,
    method: "POST",
    data: {
      _ids,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

const getAllProspects = async (jobId) => {
  return await request({
    url: apiConfigs.routes.getAllProspects,
    method: "GET",
    params: {
      jobRoleId: jobId,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

const addNewProspect = async ({
  name: fullName,
  email: primaryEmail,
  jobRoleId,
}) => {
  return await request({
    url: apiConfigs.routes.addNewProspect,
    method: "POST",
    data: {
      jobRoleId,
      fullName,
      primaryEmail,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

const getReview = async (candidateId, jobRoleId) => {
  return await request({
    url: apiConfigs.routes.getReview,
    method: "GET",
    params: {
      candidateId,
      jobRoleId,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

const editReview = async (review, reviewId) => {
  return await request({
    url: apiConfigs.routes.editReview + reviewId,
    method: "PATCH",
    data: review,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    }
  });
};

const JobRoleService = {
  getJobRoles,
  getJobRole,
  addJobRole,
  deleteJobRole,
  getAllProspects,
  addNewProspect,
  getReview,
  editReview,
};
export default JobRoleService;
