export const apiConfigs = {
  routes: {
    getJobRoles: "/evaluation/job-role",
    getJobRole: "/evaluation/job-role/?_id=",
    addJobRole: "/evaluation/job-role",
    deleteJobRole: "/evaluation/job-role/delete",
    getAllProspects: "/evaluation/prospect",
    addNewProspect: "/evaluation/prospect",
    getReview: "/evaluation/review",
    editReview: "/evaluation/review/",
    login: '/auth/google',
    logout: '/logout',
    loadUser: '/extension/user',
  },
};
