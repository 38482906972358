import React from "react";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoImage } from "./../Assets/Images/logo.svg";

import PrimaryButton from "../Components/PrimaryButton";
import { useAuth } from "../Context/AuthContext";

const ContentBody = styled("div")`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledPaper = styled(Paper)`
  height: 300px;
  width: 300px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const Error = styled(Typography)`
  color: red;
`;

const Login = () => {
  const { loginUser, loginError } = useAuth();
  const navigate = useNavigate();
  const oAuthSuccess = async (response) => {
    const user = await loginUser(response.code);
    if (user?._id) {
      navigate("/");
    }
  };

  console.log(loginError, 'log')
  const oAuthError = (response) => {
    console.log(response, "err");
    // TODO set error in store
    // returnErrors({ message: response.error, code: 500 });
  };
  return (
    <ContentBody>
      <StyledPaper elevation={12}>
      <Typography variant="PrimaryText">Welcome to</Typography>
        {/* <Typography variant="PrimaryText">InterviewBuddy</Typography> */}
        <LogoImage/>
        <Typography variant="SecondaryText">
          Please login to continue
        </Typography>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          onSuccess={oAuthSuccess}
          onFailure={oAuthError}
          responseType="code"
          accessType="offline"
          prompt="consent"
          render={({ onClick }) => (
            <PrimaryButton label="Login with google" onClick={onClick} />
          )}
        />
        <Error>{loginError ? "Unable to login. Please try again" : ""}</Error>
      </StyledPaper>
    </ContentBody>
  );
};

export default Login;
