import React from "react";
import ErrorComponent from "../Components/ErrorComponent";

const NotFound = () => {
  return (
    <>
      <ErrorComponent error="404 Not found" />
    </>
  );
};

export default NotFound;
