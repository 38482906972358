import styled from "@emotion/styled";
import React from "react";
import InputField from "./InputField";
import PrimaryButton from "./PrimaryButton";
import MenuButton from "./MenuButton";

const SearchInput = styled("div")`
  width: 354px;
  margin-right: 15px;
`;

const AddProspectBtn = styled("div")`
  width: 188px;
  margin-right: 15px;
`;

const HeaderRight = ({
  searchText,
  onClickAddProspect,
  onSearchValueChange,
}) => {
  const menuOptions = ["Delete Prospect"];
  return (
    <>
      <SearchInput>
        <InputField
          placeholder="Search"
          value={searchText}
          onChange={onSearchValueChange}
        />
      </SearchInput>
      <AddProspectBtn>
        <PrimaryButton
          label="Add prospect"
          onClick={onClickAddProspect}
        ></PrimaryButton>
      </AddProspectBtn>
      {/* <MenuButton options={menuOptions} /> */}
    </>
  );
};

export default HeaderRight;
