import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Colors from "../Theme/Colors";
import InputField from "../Components/InputField";
import PrimaryButton from "../Components/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";
import { RouteName } from "./Routers";
import ProspectsHeader from "../Components/ProspectsHeader";
import { isEmpty, notAnEmail } from "../utils";
import JobRoleService from "../Service/JobRoleService";
import ErrorComponent from "../Components/ErrorComponent";
import CircularLoader from "../Components/CircularLoader";

const CreateJobRoleCard = styled("div")`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 514px;
  background-color: white;
  min-height: 353px;
  padding: 33px 46px 38px 46px;
  box-shadow: 0px 1px 46px rgba(20, 20, 42, 0.07);
  border-radius: 7px;
  letter-spacing: -0.1px;
`;

const CardContent = styled("div")`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const NameInput = styled("div")``;

const EmailInput = styled("div")`
  margin-top: 20px;
`;

const InputLabel = styled("div")`
  color: ${Colors.grey1};
  margin-bottom: 7px;
  font-family: "Larsseit";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
`;

const CardAction = styled("div")`
  margin-top: 14px;
`;

const initialValues = {
  name: "",
  email: "",
};

const initialErrors = {
  name: false,
  email: false,
};

const CreateProspect = () => {
  const [currentJobRole, setCurrentJobRole] = useState(null);
  const [newProspect, setNewProspect] = useState(initialValues);
  const [errors, setErrors] = useState(initialErrors);
  const [jobRoleError, setJobRoleError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getJobRole();
  }, []);

  async function getJobRole() {
    try {
      const jobRole = await JobRoleService.getJobRole(params.roleId);
      setCurrentJobRole(jobRole);
    } catch (e) {
      if (e === "JOBROLE_ERROR") setJobRoleError("Invalid Job Role id");
    }
  }

  function handleChange(evt, field) {
    let value = evt.target.value;
    let prospect = { ...newProspect };
    prospect[field] = value;
    setNewProspect(prospect);
    checkError(value, field);
  }

  function checkError(value, field) {
    let validateFn = field === "name" ? validateName : validateEmail;
    let newErrors = { ...errors };
    if (validateFn(value)) {
      // seterrors only if error not displayed
      if (!errors[field]) {
        newErrors[field] = true;
        setErrors(newErrors);
      }
    } else {
      // removeerrors only if error displayed
      if (errors[field]) {
        newErrors[field] = false;
        setErrors(newErrors);
      }
    }
  }

  function validateName(value) {
    return isEmpty(value);
  }

  function validateEmail(value) {
    return isEmpty(value) || notAnEmail(value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    let error = { name: false, email: false };
    if (validateName(newProspect.name)) error.name = true;
    if (validateEmail(newProspect.email)) error.email = true;
    if (!error.name && !error.email) {
      setDisabled(true);
      addNewProspect();
    } else {
      setErrors(error);
    }
  }

  async function addNewProspect() {
    try {
      const response = await JobRoleService.addNewProspect({
        ...newProspect,
        jobRoleId: params.roleId,
      });
      if (response) {
        navigate(`${RouteName.jobRole}/${params.roleId}`);
      }
    } catch (e) {}
  }

  if (jobRoleError) return <ErrorComponent error={jobRoleError} />;

  if (currentJobRole === null) return <CircularLoader />;

  return (
    <>
      <ProspectsHeader
        roleName={currentJobRole.name}
        prospectCount={currentJobRole.numOfCandidates}
        path={`${RouteName.jobRole}/${params.roleId}`}
      />
      <CreateJobRoleCard>
        <Typography variant="PrimaryText" component="h5">
          Add prospect
        </Typography>
        <form onSubmit={handleSubmit}>
          <CardContent>
            <NameInput>
              <InputLabel>Name</InputLabel>
              <InputField
                placeholder="Enter prospect name"
                value={newProspect.name}
                error={errors.name}
                onChange={(e) => handleChange(e, "name")}
                onBlur={(e) => handleChange(e, "name")}
                inputProps={{ maxLength: 60 }}
              />
            </NameInput>
            <EmailInput>
              <InputLabel>Email</InputLabel>
              <InputField
                placeholder="Prospect email"
                value={newProspect.email}
                error={errors.email}
                onChange={(e) => handleChange(e, "email")}
                onBlur={(e) => handleChange(e, "email")}
                inputProps={{ maxLength: 60 }}
              />
            </EmailInput>
          </CardContent>
          <CardAction>
            <PrimaryButton
              label="Proceed"
              type="submit"
              disabled={disabled}
            ></PrimaryButton>
          </CardAction>
        </form>
      </CreateJobRoleCard>
    </>
  );
};

export default CreateProspect;
