import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import React from "react";
import Colors from "./Colors";
import "./../Assets/Fonts/fonts.css";

const ThemeConfig = ({ children }) => {
  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: Colors.primary,
      },
      highlight: {
        main: Colors.border,
      },
      error: {
        main: Colors.red,
      },
    },
    typography: {
      fontFamily: "Larsseit",
      PrimaryText: {
        color: Colors.black,
        fontFamily: "Larsseit",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "24px",
      },
      SecondaryText: {
        color: Colors.grey2,
        fontFamily: "Larsseit",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "22px",
      },
      button: {
        fontFamily: "Larsseit",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "none",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeConfig;
