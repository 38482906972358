import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteName } from "../Pages/Routers";
import { DataGrid } from "@mui/x-data-grid";
import styled from "@emotion/styled";
import Colors from "../Theme/Colors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ReactComponent as LogoImage } from "../Assets/Images/delete.svg";

const StyledDataGrid = styled(DataGrid)`
  & {
    border: none;
    user-select: none;
    font-family: "Larsseit";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px !important;
  }
  .MuiDataGrid-row {
    color: ${Colors.black};
  }
  .MuiDataGrid-row:hover {
    background-color: white;
    box-shadow: 0px 1px 46px rgba(20, 20, 42, 0.07);
    cursor: pointer;
  }
  .MuiDataGrid-columnHeadersInner,
  .MuiDataGrid-row,
  .MuiDataGrid-virtualScrollerRenderZone {
    width: 100%;
  }
  .MuiDataGrid-sortIcon {
    font-size: 20px;
  }
  .MuiDataGrid-columnHeaders {
    color: ${Colors.grey1};
  }
  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }
  .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }
  .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0px;
  }
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  .MuiDataGrid-columnHeader:nth-of-type(1),
  .MuiDataGrid-cell:nth-of-type(1) {
    padding-left: 50px;
  }
  .MuiDataGrid-columnHeader:nth-of-type(2),
  .MuiDataGrid-cell:nth-of-type(2) {
    flex-grow: 1;
    width: unset !important;
    min-width: unset !important;
    max-width: unset !important;
  }
  .MuiDataGrid-footerContainer {
    height: 63px;
  }
  .MuiTablePagination-displayedRows {
    font-family: "Larsseit";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px !important;
  }
  .MuiDataGrid-columnHeader:nth-of-type(3),
  .MuiDataGrid-footerContainer,
  .MuiDataGrid-cell:nth-of-type(3) {
    margin-right: 63px;
  }
  .MuiDataGrid-row {
    border-bottom: 1px solid #d9dbe9;
  }
  .MuiDataGrid-cell {
    border-bottom: none;
  }
  .MuiDataGrid-columnHeader:nth-of-type(3) {
    width: 0px !important;
    min-width: 60px !important;
  }
  .MuiDataGrid-cell:nth-of-type(3) {
    min-width: 0px !important;
  }
  .MuiDataGrid-cell:nth-of-type(4) {
    display: none;
  }
  .delete-icon {
    visibility: hidden;
  }
  .MuiDataGrid-row:hover .delete-icon {
    visibility: visible;
  }
`;

const JobRolesTable = ({ data, handleDialogOpen }) => {
  let navigate = useNavigate();
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: "jobRole",
      headerName: "Job Role",
      width: 360,
      editable: false,
    },
    {
      field: "prospects",
      headerName: "Prospects",
      width: 240,
      editable: false,
    },
    {
      field: "delete",
      headerName: "",
      width: 110,
      editable: false,
      sortable: false,
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
          handleDialogOpen(param.id);
        };
        return (
          <Tooltip title="Delete">
            <IconButton
              className="delete-icon"
              aria-label="delete"
              color="error"
              onClick={onClick}
            >
              <LogoImage />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    if (data) {
      let rows = data.map((d) => ({
        id: d._id,
        jobRole: d.name,
        prospects: d.numOfCandidates,
        delete: "",
      }));
      setRows(rows);
    }
  }, [data]);

  function handleRowClick(row) {
    navigate(`${RouteName.jobRole}/${row.id}`);
  }

  return (
    <StyledDataGrid
      rows={rows}
      autoHeight
      columns={columns}
      pageSize={8}
      rowsPerPageOptions={[8]}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableDensitySelector
      editMode="row"
      onRowClick={handleRowClick}
      headerHeight={48}
      hideFooter={false}
      loading={false}
      rowHeight={59}
      components={{
        ColumnSortedDescendingIcon: ArrowDropDownIcon,
        ColumnSortedAscendingIcon: ArrowDropUpIcon,
      }}
    />
  );
};

export default JobRolesTable;
