import styled from "@emotion/styled";
import React from "react";
import Colors from "../Theme/Colors";
// import { ReactComponent as DateImage } from "../Assets/Images/dateImg.svg";
import { ReactComponent as StarImage } from "../Assets/Images/starImg.svg";

const CandidateContainer = styled("div")`
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid #d9dbe9;
  background-color: ${(props) => props.selected && `${Colors.candidateBg}`};
  /* width: 322px; */
  `;

const CandidateContent = styled("div")`
  padding: 23px 23px 23px 77px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: fit-content;
  @media (max-width: 1080px) {
    padding: 25px 30px;
  }
`;

const CandidateTitle = styled("p")`
  font-family: "Larsseit";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.black};
  word-break: break-all;
`;

const ReviewedDate = styled("div")`
  display: flex;
  align-items: center;
  color: ${Colors.grey1};
`;

const DateIcon = styled("span")`
  margin-top: 7px;
  margin-right: 9px;
`;

const ContentText = styled("span")`
  font-family: "Larsseit";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
`;

const Rating = styled("div")`
  display: flex;
  align-items: center;
  color: ${Colors.grey1};
`;

const StarIcon = styled("span")`
  margin-top: 9px;
  margin-right: 9px;
`;

const Candidate = ({ prospect, handleChange, selected }) => {
  const handleClick = () => {
    if (selected) return;
    handleChange();
  };
  return (
    <>
      <CandidateContainer selected={selected} onClick={handleClick}>
        <CandidateContent>
          <CandidateTitle>{prospect.fullName}</CandidateTitle>
          <ReviewedDate>
            {/* <DateIcon>
              <DateImage />
            </DateIcon> */}
            {/* <ContentText>15th april 2020</ContentText> */}
          </ReviewedDate>
          <Rating>
            <StarIcon>
              <StarImage />
            </StarIcon>
            <ContentText>{prospect.reviewsCount} Reviews</ContentText>
          </Rating>
        </CandidateContent>
      </CandidateContainer>
    </>
  );
};

export default Candidate;