import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PrimaryButton from "./PrimaryButton";
import StarRating from "./stars";
import AudioRecorder from "./audioRecorder";

const EditReview = ({ review, submitHandler, closeHandler }) => {
  const {
    comments,
    meetingDetails,
    jobRoleId,
    updated_at,
    evaluationScores = [],
    userId,
    audioUrl = "",
    _id,
    candidateId,
    evaluationTemplateId,
  } = review;
  // const jobName = meetingDetails?.summary || jobRoleId?.name || "";
  // const meetingLink = meetingDetails?.hangoutLink || "";
  // const reviewDate = updated_at ? new Date(updated_at).toDateString() : "";
  // const interviewerName = userId?.first_name;
  const candidateName = candidateId?.fullName || "unknown";
  const temp =
    (evaluationScores?.length
      ? evaluationScores
      : evaluationTemplateId?.scores) || [];
  const [name, setName] = useState(candidateName);
  const [editedComments, setEditedComments] = useState(comments);
  const [scores, setScores] = useState(temp);
  const [audioBlob, setAudioBlob] = useState("");
  const [loadOldAudio, setLoadoldAudio] = useState(!!audioUrl);

  const StyledTitle = styled(DialogTitle)`
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  `;

  const CommentsBlock = styled.div`
    position: relative;
  `;

  const CharCount = styled(Typography)`
    font-size: 12px;
    line-height: 16px;
    color: #4e4b66;
    position: absolute;
    bottom: 10px;
    right: 15px;
  `;

  const Label = styled(Typography)`
    font-size: 14px;
    line-height: 16px;
    color: #4e4b66;
    margin-bottom: 10px;
  `;

  const AudioBlock = styled.div`
    margin: 15px 0;
  `;

  const submit = () => {
    const data = {
      ...(audioBlob ? { audio: audioBlob } : { audioUrl: audioUrl || "" }),
      comments: editedComments,
      candidateId: candidateId?._id,
      jobRoleId: jobRoleId?._id,
      evaluationTemplateId: evaluationTemplateId?._id,
      evaluationScores: JSON.stringify(scores),
    };
    submitHandler(data, _id, scores);
    setScores([]);
  };
  const handleRating = (score) => {
    setScores(
      scores.map((evalScore) => {
        if (evalScore.objKey === score.objKey) {
          evalScore = { ...evalScore, ...score };
        }
        return evalScore;
      })
    );
  };
  const updateComments = (value = "") => {
    if (value.length >= 201) return;
    setEditedComments(value);
  };
  const handleAudioStopAndDelete = (audioBlob = "") => {
    setAudioBlob(audioBlob);
    if (audioBlob && audioUrl) {
      setLoadoldAudio(false);
    }
  };
  return (
    <>
      <StyledTitle>
        Edit review
        <CloseIcon onClick={closeHandler} />
      </StyledTitle>
      <div className="reviewEditForm">
        {scores && scores.length ? (
          <>
            {scores.map((evaluation) => (
              <StarRating
                {...evaluation}
                rating={evaluation.score}
                displayName={evaluation.displayName}
                handleStarSelect={handleRating}
                key={evaluation.displayName}
              />
            ))}
          </>
        ) : null}
        <TextField
          type="textarea"
          value={editedComments}
          onChange={(e) => updateComments(e.target.value)}
          rows="4"
          variant="outlined"
          label="Comments"
          multiline
          maxLength="200"
        />
        <CommentsBlock>
          <CharCount>{editedComments.length} / 200</CharCount>
        </CommentsBlock>
        <AudioBlock>
          <Label>Edit/Add audio review</Label>
          <AudioRecorder
            canDelete
            audioLink={
              loadOldAudio
                ? audioUrl
                : audioBlob
                ? URL.createObjectURL(audioBlob)
                : ""
            }
            recordLimitInSeconds={90}
            onDelete={handleAudioStopAndDelete}
            onStop={handleAudioStopAndDelete}
          />
        </AudioBlock>
        {/* <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
          label="Candidate name"
          name="Candidate name"
        /> */}
        <PrimaryButton label="Submit" onClick={submit} />
      </div>
    </>
  );
};

export default EditReview;
