import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MicRecorder from "mic-recorder-to-mp3";
import {
  faMicrophone,
  faCheck,
  faTrash,
  faTimes,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./styles.module.css";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const AudioRecorder = ({
  onStop,
  className,
  audioLink,
  recordLimitInSeconds,
  canDelete,
  onDelete,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blobURL, setBlobUrl] = useState(audioLink || "");
  const [controlMode, setControlMode] = useState(false);
  const [duration, setDuration] = useState("00:00");
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [intervalId, setIntervalId] = useState();

  useEffect(() => {
    navigator.getUserMedia(
      { audio: true },
      () => {
        setIsBlocked(false);
      },
      () => {
        setIsBlocked(true);
      }
    );
  }, []);

  useEffect(() => {
    if (!blobURL) return;
    const nbPlayer = document.getElementById("nb-player");
    nbPlayer?.addEventListener("ended", pause);
    return () => {
      nbPlayer?.removeEventListener("ended", pause);
    };
  }, [blobURL, intervalId]);

  useEffect(() => {
    if (isRecording && currentTime >= recordLimitInSeconds) {
      stop();
    }
  }, [currentTime, isRecording]);

  useEffect(() => {
    if (!audioLink) return;
    const nbPlayer = document.getElementById("nb-player");
    nbPlayer?.addEventListener("loadedmetadata", setDurationInitially);
    return () =>
      nbPlayer?.removeEventListener("loadedmetadata", setDurationInitially);
  }, []);

  const setDurationInitially = () => {
    const du = document.getElementById("nb-player").duration;
    const formatted = formatDuration(du);
    setDuration(formatted);
  };

  const start = () => {
    if (isBlocked) {
      // TODO: Ask to allow microphone
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
          const intervalId = setInterval(() => {
            setCurrentTime(Mp3Recorder.context.currentTime);
          }, 1000);
          setIntervalId(intervalId);
        })
        .catch((e) => console.error(e));
    }
  };

  const cancel = () => {
    Mp3Recorder.stop();
    setIsRecording(false);
    resetInterval();
    setCurrentTime(0);
  };

  const resetInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const stop = () => {
    resetInterval();
    setCurrentTime(0);
    Mp3Recorder.stop()
      .getMp3()
      // eslint-disable-next-line no-unused-vars
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        console.log(blobURL)
        setBlobUrl(blobURL);
        setIsRecording(false);
        calculateDuration();
        onStop && onStop(blob);
      })
      .catch((e) => console.log(e));
  };

  const play = () => {
    const nbPlayer = document.getElementById("nb-player");
    nbPlayer?.play();
    !controlMode && setControlMode(true);
    setIsPlaying(true);
    setCurrentTime(nbPlayer.currentTime);
    const intervalId = setInterval(() => {
      setCurrentTime(nbPlayer.currentTime);
    }, 1000);
    setIntervalId(intervalId);
    setDuration(formatDuration(nbPlayer.duration));
  };

  const pause = () => {
    const nbPlayer = document.getElementById("nb-player");
    nbPlayer?.pause();
    setIsPlaying(false);
    setCurrentTime(nbPlayer.currentTime);
    resetInterval();
  };

  const offControlMode = () => {
    const nbPlayer = document.getElementById("nb-player");
    controlMode && setControlMode(false);
    pause();
    resetInterval();
    setCurrentTime(0);
    nbPlayer.currentTime = 0;
  };

  const calculateDuration = () => {
    const duration = Mp3Recorder.context?.currentTime;
    if (!duration) return;
    const formatedDuration = formatDuration(duration);
    setDuration(formatedDuration);
  };

  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration - minutes * 60);
    return (
      minutes.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      }) +
      ":" +
      seconds.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      })
    );
  };

  const deleteBlob = () => {
    setBlobUrl("");
    setControlMode(false);
    resetInterval();
    onDelete && onDelete();
  };

  const getPlayTime = () => {
    return `${formatDuration(currentTime)}/${duration}`;
  };

  return (
    <div className={`${styles.main} ${className}`}>
      {blobURL ? (
        <>
          <audio id="nb-player" src={blobURL} />
          {controlMode ? (
            <div className={styles.fullControlCard}>
              {canDelete && (
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={deleteBlob}
                  className={styles.delete}
                />
              )}

              <div className={styles.middle}>
                <aside>
                  <FontAwesomeIcon
                    icon={isPlaying ? faPause : faPlay}
                    onClick={isPlaying ? pause : play}
                  />
                  <span>{isPlaying ? "Playing" : "Paused"}</span>
                </aside>
                <div className={styles.middletxt}>
                  <span>{getPlayTime()}</span>
                </div>
              </div>
              <FontAwesomeIcon icon={faTimes} onClick={offControlMode} />
            </div>
          ) : (
            <div className={styles.audioPlayer}>
              <FontAwesomeIcon icon={faPlay} onClick={play} />
              <span className={styles.timer}>{duration}</span>
            </div>
          )}
        </>
      ) : isRecording ? (
        <div className={styles.fullControlCard}>
          <FontAwesomeIcon icon={faTimes} onClick={cancel} className={styles.delete}/>
          <div className={styles.middle}>
            <FontAwesomeIcon icon={faMicrophone} className={styles.mic} />
            <div className={styles.middletxt}>
              <span>Recording</span>
              <span>{formatDuration(currentTime)}</span>
            </div>
          </div>
          <FontAwesomeIcon icon={faCheck} onClick={stop} className={styles.check}/>
        </div>
      ) : (
        <div className={styles.record} onClick={start}>
          <FontAwesomeIcon icon={faMicrophone} className={styles.mic} />
          <span className={styles.recordTxt}>Record Comments</span>
        </div>
      )}
    </div>
  );
};

AudioRecorder.defaultProps = {
  recordLimitInSeconds: 90,
  canDelete: false,
};

AudioRecorder.propTypes = {
  onStop: PropTypes.func,
  className: PropTypes.string,
  audioLink: PropTypes.string,
  recordLimitInSeconds: PropTypes.number,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

export default AudioRecorder;
