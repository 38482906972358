export function isEmpty(value) {
  return value.trim() === "";
}

export const notAnEmail = (email) => {
  return !String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function formatDate(dateString) {
  if (!dateString) return '';
  const d = new Date(dateString).toDateString();
  const x = d.split(' ');
  const currentDate = x[2].concat(' ').concat(x[1]).concat(' ').concat(x[3]);
  return currentDate;
}
