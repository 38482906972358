import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NoJobRoleImage } from "./../Assets/Images/noJobRole.svg";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import PrimaryButton from "../Components/PrimaryButton";
import { RouteName } from "./Routers";
import ProspectsHeader from "../Components/ProspectsHeader";

const ContentBody = styled("div")`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTypography = styled(Typography)`
  margin-top: 88px;
`;

const ButtonContainer = styled("div")`
  width: 422px;
  margin-top: 21px;
`;

const EmptyProspects = ({ jobRole }) => {
  const navigate = useNavigate();

  function onClickAddProspect() {
    navigate(`${RouteName.jobRole}/${jobRole._id}${RouteName.createProspect}`);
  }

  return (
    <>
      <ProspectsHeader roleName={jobRole.name} path={`${RouteName.home}`} />
      <ContentBody>
        <div>
          <NoJobRoleImage />
        </div>
        <StyledTypography variant="PrimaryText" component="h5">
          No prospects yet
        </StyledTypography>
        <ButtonContainer>
          <PrimaryButton
            label="Add prospect"
            onClick={onClickAddProspect}
          ></PrimaryButton>
        </ButtonContainer>
      </ContentBody>
    </>
  );
};

export default EmptyProspects;
