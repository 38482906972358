import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import ProspectsHeader from "../Components/ProspectsHeader";
import { RouteName } from "./Routers";
import Candidate from "../Components/Candidate";
import JobRoleService from "../Service/JobRoleService";
import CircularLoader from "../Components/CircularLoader";
import ErrorComponent from "../Components/ErrorComponent";
import HeaderRight from "../Components/ProspectHeaderRight";
import { escapeRegExp } from "../utils";
import EmptyCandidate from "../Components/EmptyCandidate";
import EvaluationDetails from "../Components/EvaluationDetails";
import EditReview from "../Components/EditReview";
// import { useAuth } from '../Context/AuthContext';
import MeetingDetails from "../Components/MeetingDetails";
import { Typography } from "@mui/material";

const Header = styled.header`
  margin-bottom: 12px;
`;

const Container = styled(Grid)`
  border-top: 1px solid #d9dbe9;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

const Candidates = styled(Grid)`
  overflow-y: auto;
  height: calc(100vh - 180px);
  grid-column: 1/3;
  overflow-x: hidden;
  @media (max-width: 1300px) {
    grid-column: 1/4;
  }
  @media (max-width: 768px) {
    grid-column: 1/5;
  }
`;

const Details = styled(Grid)`
  flex-grow: 1;
  background-color: #ffff;
  border-left: 1px solid #d9dbe9;
  overflow-y: auto;
  position: relative;
  height: 100%;
  flex-wrap: nowrap;
  grid-column: 3/13;
  padding: 25px;
  @media (max-width: 1300px) {
    grid-column: 4/13;
  }
  @media (max-width: 768px) {
    grid-column: 5/13;
  }
`;

const UpcomingMeeting = styled(Typography)`
  padding: 15px 0;
`;

const Dashboard = ({ jobRole, prospects }) => {
  let params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState(prospects);
  const [fetchReview, setFetchReview] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [activeReview, setActiveReview] = useState(null);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    const currentId = searchParams.get('candidateId');
    if(currentId) {
      setSearchParams([])
      handleCandidateSelection({_id: currentId})
    }
  },[])
  function onClickAddProspect() {
    navigate(`${RouteName.jobRole}/${jobRole._id}${RouteName.createProspect}`);
  }

  function handleCandidateSelection(prospect) {
    setFetchReview(true);
    getReview(prospect);
  }

  async function getReview(prospect, showUpdate = true) {
    setSelectedCandidate(prospect._id || null);
    try {
      const response = await JobRoleService.getReview(
        prospect._id,
        jobRole._id
      );
      if (response) {
        const rw = response.evaluationReviews || [];
        const upcomingMeetings = response.upcomingMeetings || [];
        if (showUpdate) {
          setFetchReview(false);
          setReviews(rw);
          setUpcomingMeetings(upcomingMeetings);
        }
        return response;
      }
    } catch (error) { }
  }
  function onSearchValueChange(evt) {
    setSearchText(evt.target.value);
    const searchRegex = new RegExp(escapeRegExp(evt.target.value), "i");
    const filteredProspects = prospects.filter((prospect) => {
      return searchRegex.test(prospect["fullName"].toString());
    });
    setFilteredRows(filteredProspects);
  }

  function showEditPopup(review) {
    setActiveReview(review);
    setShowDialog(true);
  }

  async function handleReviewEdit(data, reviewId) {
    setShowDialog(false);
    try {
      const formData = new FormData();
      Object.keys(data).map((k) => {
        data[k] && formData.append(k, data[k]);
      });
      const res = await JobRoleService.editReview(formData, reviewId);
      if (res?._id && res?.candidateId) {
        // const index = reviews.findIndex((rw) => rw._id === reviewId);
        // const updated = [...reviews];
        // updated[index] = res;
        // setReviews(updated);
        setFetchReview(true);
        const response = await getReview({ _id: res.candidateId }, false);
        /* Star component has bug that prevents updating value externally. So while 
          updating the review, the entire reviews is unmounted and then mounted.
          Refer: https://github.com/ertanhasani/react-stars/issues/16
        */
        setReviews([]);
        setReviews(response.evaluationReviews);
        setFetchReview(false);
        setToastMessage("Review updated successfully!!");
      }
    } catch (err) {
      setToastMessage("Error updating review");
    }
  }

  return (
    <>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <EditReview
          review={activeReview}
          submitHandler={handleReviewEdit}
          closeHandler={() => setShowDialog(false)}
        />
      </Dialog>
      <Header>
        <ProspectsHeader
          roleName={jobRole.name}
          prospectCount={jobRole.numOfCandidates}
          path={`${RouteName.home}`}
          headerRight={
            <HeaderRight
              searchText={searchText}
              onClickAddProspect={onClickAddProspect}
              onSearchValueChange={onSearchValueChange}
            />
          }
        />
      </Header>
      <Container container spacing={0}>
        <Candidates item>
          {filteredRows.length === 0 ? (
            <EmptyCandidate />
          ) : (
            filteredRows.map((prospect, index) => (
              <Candidate
                key={index}
                prospect={prospect}
                selected={prospect._id === selectedCandidate}
                handleChange={() => handleCandidateSelection(prospect)}
              />
            ))
          )}
        </Candidates>
        <Details>
          {console.log('reviews && upcomingMeetings>>>>>', reviews, upcomingMeetings)}
          {!selectedCandidate ? (
            <ErrorComponent error={"No prospect selected"} />
          ) : fetchReview ? (
            <CircularLoader />
          ) : !reviews.length && !upcomingMeetings.length ? (
            <ErrorComponent error={"No evaluations and Upcoming meetings"} />
          ) : (
            <>
              {reviews.filter(rw => rw.meetingDetails?.hangoutLink).map((review, i) => (
                <EvaluationDetails
                  review={review}
                  key={i}
                  onEdit={() => showEditPopup(review)}
                />
              ))}
              {!!upcomingMeetings.length && 
                <>
                  <UpcomingMeeting variant="PrimaryText" component="p">Upcoming Interviews</UpcomingMeeting>
                  {upcomingMeetings.map(meeting => (<MeetingDetails details={meeting.meetingDetails} showMeetLink={true} />))}
                </>
              }
            </>
          )}
        </Details>
      </Container>
      <Snackbar
        open={!!toastMessage}
        onClose={() => setToastMessage("")}
        message={toastMessage}
        autoHideDuration={5000}
        TransitionComponent={Slide}
      />
    </>
  );
};

export default Dashboard;
