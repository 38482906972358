import React, { useState } from "react";
import styled from "@emotion/styled";
import BackButton from "../Components/BackButton";
import { Typography } from "@mui/material";
import Colors from "../Theme/Colors";
import InputField from "../Components/InputField";
import PrimaryButton from "../Components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { RouteName } from "./Routers";
import { isEmpty } from "../utils";
import JobRoleService from "../Service/JobRoleService";

const ContentHeader = styled("div")`
  padding-top: 21px;
  margin-left: 43px;
  width: fit-content;
`;

const CreateJobRoleCard = styled("div")`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 514px;
  background-color: white;
  min-height: 271px;
  padding: 33px 46px 30px 46px;
  box-shadow: 0px 1px 46px rgba(20, 20, 42, 0.07);
  border-radius: 7px;
  letter-spacing: -0.1px;
`;

const CardContent = styled(Typography)`
  color: ${Colors.grey1};
  margin-top: 10px;
`;

const CardInput = styled("div")`
  margin-top: 20px;
`;

const CardAction = styled("div")`
  margin-top: 14px;
`;

const CreateJobRole = () => {
  const [newJobRole, setNewJobRole] = useState("");
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  function handleTextChange(evt) {
    let value = evt.target.value;
    setNewJobRole(value);
    isEmpty(value) ? setError(true) : setError(false);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    if (error) return;
    if (isEmpty(newJobRole)) {
      setError(true);
    } else {
      setDisabled(true);
      addNewJobRole();
    }
  }

  async function addNewJobRole() {
    try {
      const response = await JobRoleService.addJobRole(newJobRole);
      if (response) {
        navigate(RouteName.home);
      }
    } catch (e) {}
  }

  return (
    <>
      <ContentHeader>
        <BackButton path={RouteName.home} />
      </ContentHeader>
      <CreateJobRoleCard>
        <Typography variant="PrimaryText" component="h5">
          Create Job role
        </Typography>
        <CardContent variant="SecondaryText" component="p">
          Create Job roles to add people and evaluate them organised and easily.
        </CardContent>
        <form onSubmit={handleSubmit}>
          <CardInput>
            <InputField
              placeholder="Enter a Job role name"
              value={newJobRole}
              error={error}
              autoFocus
              onChange={handleTextChange}
              onBlur={handleTextChange}
              inputProps={{ maxLength: 42 }}
            />
          </CardInput>
          <CardAction>
            <PrimaryButton
              label="Proceed"
              type="submit"
              disabled={disabled}
            ></PrimaryButton>
          </CardAction>
        </form>
      </CreateJobRoleCard>
    </>
  );
};

export default CreateJobRole;
