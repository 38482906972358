import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Typography } from "@mui/material";
import Colors from "../Theme/Colors";

const ButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LeftIcon = styled(ChevronLeftIcon)`
  color: ${Colors.grey2};
  margin-right: 3px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackButton = ({ customHandler, path }) => {
  const navigate = useNavigate();
  return (
    <ButtonContainer
      onClick={customHandler ? customHandler : () => navigate(path || -1)}
    >
      <LeftIcon />
      <Typography variant="SecondaryText" component="p">
        Back
      </Typography>
    </ButtonContainer>
  );
};

export default BackButton;
