import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import styles from "./styles.module.css";

const ratings = {
  1: "Very bad",
  2: "Bad",
  3: "Average",
  4: "Good",
  5: "Excellent",
};

const StarRating = ({
  displayName,
  handleStarSelect,
  objKey,
  rating,
  editable = true,
}) => {
  const [text, setText] = useState("");

  const handleSelect = (val) => {
    setText(ratings[Math.ceil(val)]);
    handleStarSelect({ objKey, displayName, score: val });
  };

  useEffect(() => {
    if (rating) {
      setText(ratings[Math.ceil(parseFloat(rating))]);
    }
  }, []);

  // const stars = {
  //   emptyIcon: <i className="far fa-star" />,
  //   halfIcon: <i className="fa fa-star-half-alt" />,
  //   filledIcon: <i className="fa fa-star" />,
  // };
  return (
    <div className={styles.scoreCard}>
      <span className={styles.star}>
        <label>{displayName}</label>
        <ReactStars
          classNames={styles.starIcon}
          count={5}
          {...(rating && { value: rating })}
          onChange={handleSelect}
          size={30}
          isHalf={true}
          activeColor="#ffd700"
          edit={editable}
          // {...stars}
        />
      </span>
      <span className={styles.starDescription}>
        <p className={`${styles.scoreDescription}`}>{text}</p>
      </span>
    </div>
  );
};

export default StarRating;
