const Colors = {
  background: "#F6F6F6",
  white: "#FFFFFF",
  black: "#14142A",
  primary: "#2934D0",
  grey1: "#4E4B66",
  grey2: "#6E7191",
  border: "#D9DBE9",
  red: "#D32E2A",
  candidateBg: " #1ECCFF",
};

export default Colors;
