import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NoJobRoleImage } from "./../Assets/Images/noJobRole.svg";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import PrimaryButton from "../Components/PrimaryButton";
import { RouteName } from "./Routers";

const ContentHeader = styled("div")`
  padding-top: 42px;
  margin-left: 52px;
`;

const ContentBody = styled("div")`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTypography = styled(Typography)`
  margin-top: 88px;
`;

const ButtonContainer = styled("div")`
  width: 422px;
  margin-top: 21px;
`;

const EmptyJobRoles = () => {
  const navigate = useNavigate();
  function onClickCreateRole() {
    navigate(RouteName.createJobRole);
  }
  return (
    <>
      <ContentHeader>
        <Typography variant="PrimaryText" component="h5">
          All Job roles
        </Typography>
      </ContentHeader>
      <ContentBody>
        <div>
          <NoJobRoleImage />
        </div>
        <StyledTypography variant="PrimaryText" component="h5">
          No job roles yet
        </StyledTypography>
        <ButtonContainer>
          <PrimaryButton
            label="Create Job role"
            onClick={onClickCreateRole}
          ></PrimaryButton>
        </ButtonContainer>
      </ContentBody>
    </>
  );
};

export default EmptyJobRoles;
