import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";

const CandidateContainer = styled("div")`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 322px;
`;
const EmptyCandidate = () => {
  return (
    <>
      <CandidateContainer>
        <Typography variant="SecondaryText" component="p">
          No prospect found
        </Typography>
      </CandidateContainer>
    </>
  );
};

export default EmptyCandidate;
