import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";
import Colors from "../Theme/Colors";
import BackButton from "./BackButton";

const ContentHeader = styled("div")`
  display: flex;
  padding-top: 21px;
  align-items: center;
  margin-right: 22px;
`;

const HeaderLeft = styled("div")`
  flex-grow: 1;
  .back-button {
    margin-left: 43px;
    width: fit-content;
  }
  .header-title {
    margin-left: 49px;
    margin-top: 14px;
  }
  .header-content {
    color: ${Colors.grey1};
    margin-left: 49px;
    margin-top: 7px;
    font-family: "Larsseit";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
`;

const HeaderRight = styled("div")`
  display: flex;
`;

const ProspectsHeader = ({ roleName, prospectCount, headerRight, path }) => {
  return (
    <ContentHeader>
      <HeaderLeft>
        <div className="back-button">
          <BackButton path={path} />
        </div>
        <div className="header-title">
          <Typography variant="PrimaryText" component="h5">
            {roleName}
          </Typography>
        </div>
        <div className="header-content">{`${prospectCount || 0} ${
          prospectCount === 1 ? "candidate" : "candidates"
        }`}</div>
      </HeaderLeft>
      <HeaderRight>{headerRight}</HeaderRight>
    </ContentHeader>
  );
};

export default ProspectsHeader;
