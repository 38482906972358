import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";

const Container = styled("div")`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const ErrorComponent = ({ error }) => {
  return (
    <>
      <Container>
        <Typography variant="PrimaryText" component="h5">
          {error}
        </Typography>
      </Container>
    </>
  );
};

export default ErrorComponent;
