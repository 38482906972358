import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import meetIcon from "../Assets/Images/meet.svg";
import { formatDate } from "../utils";

const DateField = styled(Typography)`
  font-size: 16px;
  line-height: 16px;
  color: #4e4b66;
`;

const GoToMeet = styled.a`
  background: #ffffff;
  border: 1px solid #2934d0;
  border-radius: 10px;
  width: 160px;
  text-decoration: none;
  text-align: center;
  padding: 7%;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #2934d0;
`;
const MeetingBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9dbe9;
  padding: 15px;

  aside {
    display: flex;
    align-items: center;
    gap: 15px;
  }

`;

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #14142a;
  margin: 0 0 10px 0;
`;
const MeetingDetails = ({ details, showMeetLink }) => {

  const { summary, hangoutLink } = details;
  const scheduleDate = formatDate(details?.start?.dateTime);

  return (
    <MeetingBlock>
      <aside>
        <img src={meetIcon} alt="Google meet logo" />
        <div>
          <Title variant="primaryText" as="h3">
            {summary}
          </Title>
          <DateField variant="SecondaryText">Scheduled on {scheduleDate}</DateField>
        </div>
      </aside>
      {showMeetLink && <aside>
        <GoToMeet href={hangoutLink} target="_blank">
          {" "}
          Go to meeting
        </GoToMeet>
      </aside>}
    </MeetingBlock>
  );
};

export default MeetingDetails;
