import Routers from "./Pages/Routers";
import ThemeConfig from "./Theme";
import "./App.css";
import styled from "@emotion/styled";
import { AuthProvider } from "./Context/AuthContext";

const MainWrapper = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

function App() {
  return (
    <ThemeConfig>
      <AuthProvider>
        <MainWrapper>
          <Routers />
        </MainWrapper>
      </AuthProvider>
    </ThemeConfig>
  );
}

export default App;
