import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const DialogComponent = ({ dialogId, handleDelete, handleDialogClose }) => {
  return (
    <div>
      <Dialog
        open={dialogId ? true : false}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Job role</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Job role?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDelete} autoFocus>
            DELETE
          </Button>
          <Button onClick={handleDialogClose}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogComponent;
