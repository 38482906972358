import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import CircularLoader from "../Components/CircularLoader";
import DialogComponent from "../Components/DialogComponent";
import InputField from "../Components/InputField";
import JobRolesTable from "../Components/JobRolesTable";
import PrimaryButton from "../Components/PrimaryButton";
import JobRoleService from "../Service/JobRoleService";
import EmptyJobRoles from "./../Pages/EmptyJobRoles";
import { RouteName } from "./Routers";
import { escapeRegExp } from "../utils";
import { useAuth } from "../Context/AuthContext";

const ContentHeader = styled("div")`
  padding: 28px 24px 40px 52px;
  display: flex;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  flex-grow: 1;
`;

const SearchInput = styled("div")`
  width: 354px;
  margin-right: 16px;
`;

const ActionButton = styled("div")`
  width: 300px;
`;

const ContentBody = styled("div")``;

const AllJobRoles = () => {
  const { appLoading } = useAuth();
  const navigate = useNavigate();
  const [jobRoles, setJobRoles] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState(null);
  const [dialogId, setDialogId] = useState(null);

  useEffect(() => {
    getJobRolesAction();
  }, []);

  useEffect(() => {
    setFilteredRows(jobRoles);
  }, [jobRoles]);

  async function getJobRolesAction() {
    try {
      const jobRoleResult = await JobRoleService.getJobRoles();
      if (jobRoleResult) setJobRoles(jobRoleResult);
    } catch (error) {
      setJobRoles([]);
      setFilteredRows([])
    }
  }

  function handleDialogOpen(id) {
    setDialogId(id);
  }

  async function handleDelete() {
    try {
      const response = await JobRoleService.deleteJobRole([dialogId]);
      if (response) {
        let roles = [...jobRoles];
        let filteredRoles = roles.filter((role) => role._id !== dialogId);
        setJobRoles(filteredRoles);
        setDialogId(null);
      }
    } catch (error) {}
  }

  function onClickCreateRole() {
    navigate(RouteName.createJobRole);
  }

  function onSearchValueChange(evt) {
    setSearchText(evt.target.value);
    const searchRegex = new RegExp(escapeRegExp(evt.target.value), "i");
    const filteredRoles = jobRoles.filter((row) => {
      return searchRegex.test(row["name"].toString());
    });
    setFilteredRows(filteredRoles);
  }
  if (jobRoles === null || filteredRows === null || appLoading)
    return <CircularLoader />;

  return (
    <>
      {jobRoles.length === 0 ? (
        <EmptyJobRoles />
      ) : (
        <>
          <DialogComponent
            dialogId={dialogId}
            handleDelete={handleDelete}
            handleDialogClose={() => setDialogId(null)}
          />
          <ContentHeader>
            <StyledTypography variant="PrimaryText" component="h5">
              All Job roles
            </StyledTypography>
            <SearchInput>
              <InputField
                placeholder="Search"
                value={searchText}
                onChange={onSearchValueChange}
              />
            </SearchInput>
            <ActionButton>
              <PrimaryButton
                label="Create new Job role"
                onClick={onClickCreateRole}
              ></PrimaryButton>
            </ActionButton>
          </ContentHeader>
          <ContentBody>
            <JobRolesTable
              data={filteredRows}
              handleDialogOpen={handleDialogOpen}
            />
          </ContentBody>
        </>
      )}
    </>
  );
};

export default AllJobRoles;
