import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import StarRating from "./stars";
import { useAuth } from "../Context/AuthContext";
import MeetingDetails from "./MeetingDetails";

const Reviewblock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-bottom: 1px solid #d9dbe9;
  min-height: 20%;
  padding-bottom: 15px;

  svg {
    flex-grow: 0;
    flex-shrink: 0;
    height: 50px;
    width: 50px;
    color: #37ccfb;
    &.editIcon {
      cursor: pointer;
      width: 20px;
      height: auto;
      color: #233991;
    }
  }
`;

const DateField = styled(Typography)`
  font-size: 16px;
  line-height: 16px;
  color: #4e4b66;
`;

const SectionTitle = styled(DateField)`
  margin: 10px 0;
`;

const FieldBlock = styled.div`
  color: #14142a;
  font-size: 16px;
  line-height: 23px;
`;

const Comments = styled(Typography)`
  color: #14142a;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
`;

const RatingBlock = styled.div`
  /* padding: 0 30px; */
`;

const ProfileName = styled.div`
  display: flex;
  gap: 10px;

  div {
    flex-grow: 1;
  }
`;
const EvaluationDetails = ({ review, onEdit }) => {
  const {
    comments,
    meetingDetails,
    jobRoleId,
    updated_at,
    evaluationScores = [],
    userId,
    audioUrl,
    _id,
  } = review;
  // console.log(review, 'rev')
  const { user: recruiter } = useAuth();
  const title = meetingDetails?.summary || jobRoleId?.name || "";
  const reviewDate = updated_at ? new Date(updated_at).toDateString() : "";
  const interviewerName = userId?.first_name;
  const interviewerEmail = userId?.email;
  const canEdit = recruiter?.email === interviewerEmail;
  meetingDetails.summary = title;
  return (
    <Reviewblock>
      <MeetingDetails details={meetingDetails} showMeetLink={false} />
      {!!interviewerName && (
        <ProfileName>
          <AccountCircleIcon />
          <div>
            <Typography variant="primaryText" as="h3">
              {interviewerName}
            </Typography>
            <DateField variant="SecondaryText">{reviewDate}</DateField>
          </div>
          {!!canEdit && <EditIcon onClick={onEdit} className="editIcon" />}
        </ProfileName>
      )}
      {evaluationScores && evaluationScores.length ? (
        <RatingBlock>
          {evaluationScores.map((evaluation) => (
            <StarRating
              rating={evaluation.score}
              displayName={evaluation.displayName}
              editable={false}
              key={evaluation.displayName}
            />
          ))}
        </RatingBlock>
      ) : null}
      {!!comments && (
        <FieldBlock>
          <SectionTitle>Comments/review</SectionTitle>
          <Comments>{comments}</Comments>
        </FieldBlock>
      )}
      {audioUrl && (
        <FieldBlock>
          <SectionTitle>Audio review</SectionTitle>
          <audio src={audioUrl} controls />
        </FieldBlock>
      )}
    </Reviewblock>
  );
};

export default EvaluationDetails;
