import React from "react";
import styled from "@emotion/styled";
import Colors from "../Theme/Colors";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LogoImage } from "./../Assets/Images/logo.svg";
import { ReactComponent as ProfileImage } from "./../Assets/Images/avatar.svg";
import { RouteName } from "../Pages/Routers";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../Context/AuthContext";

const StyledNavbar = styled("nav")`
  display: flex;
  align-items: center;
  height: 60px;
  min-height: 60px;
  background-color: ${Colors.white};
`;

const NavbarLeft = styled("div")`
  flex-grow: 1;
  margin-left: 38px;
  line-height: 0;
`;

const NavbarRight = styled("div")`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 16px;

  svg {
    cursor: pointer;
  }
`;

const DisplayName = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #0ca6ff;
  color: #fff;
`;
const Navbar = () => {
  const { logoutUser, user } = useAuth();
  const navigate = useNavigate();
  const displayName = user?.first_name?.substring(0, 2) || "❄";
  const onLogout = async () => {
    await logoutUser();
    navigate("/");
  };
  return (
    <StyledNavbar>
      <NavbarLeft>
        <Link to={RouteName.home}>
          <LogoImage />
        </Link>
      </NavbarLeft>
      <NavbarRight>
        {/* <ProfileImage /> */}
        <DisplayName>
          {displayName}
        </DisplayName>
        <LogoutIcon onClick={onLogout} />
      </NavbarRight>
    </StyledNavbar>
  );
};

export default Navbar;
