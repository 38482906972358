import request from "../utils/api";
import { apiConfigs } from "../utils/configs";

export const getToken = () => localStorage.getItem("authToken");

export const login = async (oAuthCode) => {
  const body = {
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    authCode: oAuthCode,
    applicationType: "web",
  };
  try {
    const response = await request({
      url: apiConfigs.routes.login,
      method: "POST",
      data: body,
    });
    if (response?.access_token) {
      // localStorage.setItem("authToken", response?.access_token);
      return response;
    }
  } catch (err) {
    console.log(err, "login error");
    localStorage.setItem("authToken", "");
  }
};

export const loadUser = async (token) => {
  try {
    const user = await request({
      url: apiConfigs.routes.loadUser,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
    if (user?._id) {
      return user;
    }
  } catch {
    console.log("error loading user");
  }
};

export const logout = async (token) => {
  try {
    const res = await request({
      url: apiConfigs.routes.logout,
      method: "POST",
      data: {},
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return true;
  } catch {
    return false;
    console.log("logout error");
  }
};
