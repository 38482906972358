import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import React from "react";

const Loader = styled("div")`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const CircularLoader = () => {
  return (
    <Loader>
      <CircularProgress size={54} />
    </Loader>
  );
};

export default CircularLoader;
