import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React from "react";

const StyledButton = styled(Button)`
  padding-top: 18px;
  padding-bottom: 14px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgb(44 90 246 / 23%);
`;

const PrimaryButton = (props) => {
  return (
    <StyledButton variant="contained" fullWidth {...props}>
      {props.label}
    </StyledButton>
  );
};

export default PrimaryButton;
