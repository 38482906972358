import styled from "@emotion/styled";
import { FormControl, OutlinedInput } from "@mui/material";
import React from "react";
import Colors from "../Theme/Colors";
import "./../Assets/Fonts/fonts.css";

const StyledTextField = styled(FormControl)`
  width: 100%;
  & .MuiOutlinedInput-input {
    color: ${Colors.grey2};
    font-family: "Larsseit";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    height:16px;
    background-color: white;
    padding: 16px 18px;
    border-radius:10px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 10px;
    border-color: #d9dbe9;
  }
  }
`;

const InputField = (props) => {
  return (
    <>
      <StyledTextField>
        <OutlinedInput {...props} color="highlight" />
      </StyledTextField>
    </>
  );
};

export default InputField;
