import React, { useState } from "react";
import styled from "@emotion/styled";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Colors from "../Theme/Colors";
import { ReactComponent as LogoImage } from "../Assets/Images/delete.svg";

const MoreIconBtn = styled(IconButton)`
  color: ${Colors.grey1};
`;

const MenuAction = styled("div")`
  display: flex;
  align-items: center;
`;

const MenuText = styled("p")`
  color: ${Colors.black};
  font-family: "Larsseit";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-left: 7px;
`;

const MenuButton = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <>
      <MoreIconBtn
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={menuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </MoreIconBtn>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            marginTop: "28px",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={handleMenuClose} disabled>
            <MenuAction>
              <LogoImage />
              <MenuText>{option}</MenuText>
            </MenuAction>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButton;
