import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { login, logout, loadUser } from "../Service/AuthService";

let initialToken = "";
let initialUser = {};
if (window.localStorage.getItem("authToken")) {
  const authtoken = window.localStorage.getItem("authToken") || "";
  initialToken = authtoken;
}

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`Context missing provider`);
  }
  return context;
};

export const AuthProvider = (props) => {
  const [, setCookie, removeCookie] = useCookies(["authToken"]);
  const [token, setToken] = useState(initialToken);
  const [user, setUser] = useState(initialUser);
  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState(false);

  const loadUserInitially = async (tk) => {
    if (!tk) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const user = await loadUser(tk);
      if (user) setUser(user);
    } finally {
      setLoading(false);
    }
  };

  const loginUser = async (authCode) => {
    setLoading(true);
    setLoginError(false);
    try {
      const authData = await login(authCode);
      if (authData?.access_token) {
        const user = await loadUser(authData?.access_token);
        if (user?._id) {
          setToken(authData?.access_token);
          setUser(user);
          window.localStorage.setItem("authToken", authData?.access_token);
          setCookie("authToken", authData?.access_token, {
            path: "/",
          });
          return user;
        }
      }
      setLoginError(true);
    } catch (err) {
      console.log(err, "login error");
      setLoginError(true);
    } finally {
      setLoading(false);
    }
  };

  const logoutUser = async () => {
    setLoading(true);
    try {
      const isLoggedout = await logout(token);
      if (isLoggedout) {
        setToken("");
        setUser({});
      }
    } finally {
      window.localStorage.setItem("authToken", "");
      setLoading(false);
      removeCookie("authToken", { path: "/" })
    }
  };

  useEffect(() => {
    loadUserInitially(token);
  }, []);
  return (
    <AuthContext.Provider
      value={{
        token,
        loginUser,
        user,
        logoutUser,
        appLoading: loading,
        loginError,
      }}
      {...props}
    />
  );
};
