import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CircularLoader from "../Components/CircularLoader";
import ErrorComponent from "../Components/ErrorComponent";
import JobRoleService from "../Service/JobRoleService";
import Dashboard from "./Dashboard";
import EmptyProspects from "./EmptyProspects";

const AllProspects = () => {
  const [currentJobRole, setCurrentJobRole] = useState(null);
  const [prospects, setProspects] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();

  useEffect(() => {
    getJobRole();
  }, []);

  async function getJobRole() {
    try {
      const jobRole = await JobRoleService.getJobRole(params.roleId);
      setCurrentJobRole(jobRole);
      if (jobRole && jobRole._id === params.roleId && jobRole.numOfCandidates) {
        getAllProspects();
      }
      if (!jobRole.numOfCandidates) setProspects([]);
    } catch (e) {
      if (e === "JOBROLE_ERROR") setError("Invalid Job Role id");
    }
  }

  async function getAllProspects() {
    try {
      const prospects = await JobRoleService.getAllProspects(params.roleId);
      if (prospects) setProspects(prospects);
    } catch (e) {}
  }

  if (error) return <ErrorComponent error={error} />;

  if (prospects === null || currentJobRole === null) return <CircularLoader />;

  return (
    <>
      {prospects.length === 0 ? (
        <EmptyProspects jobRole={currentJobRole} />
      ) : (
        <Dashboard jobRole={currentJobRole} prospects={prospects} />
      )}
    </>
  );
};

export default AllProspects;
